@import '../../../../styles/shared';

.selectableTableRow {
  cursor: pointer;
}

.tradingButton {
  position: absolute;
  right: 0;
}

.rowsPerPage {
  width: 5rem;
}

.cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  display: inline-block;

  width: 1rem;
  height: 100%;

  cursor: col-resize;

  transform: translateX(50%);
}

.flex1 {
  flex: 1;
}

.gap {
  gap: 1rem;
}
