@include media-breakpoint-up(md) {
  .modal-header {
    padding: 1rem 2rem;
    padding-top: 2rem;
  }

  .modal-body {
    padding: 1rem 2rem;
  }

  .modal-footer {
    padding: 1rem 2rem;
    padding-bottom: 2rem;
  }
}

.modal,
.modal-dialog {
  color: $text-color-dark;
}

.modal,
.overlay {
  .popover {
    z-index: $zindex-modal + 10;
  }

  .tooltip {
    z-index: $zindex-modal + 20;
  }
}

// TODO: Work with inputs in modal?
// body.modal-open {
//   overflow: hidden !important;
//   position: fixed;
//   width: 100%;

//   input {
//     -webkit-transform: translateZ(0);
//   }
// }
