.tableBody {
  tr {
    td {
      white-space: nowrap;
    }
  }

  tr:last-child {
    td {
      border-bottom-width: 0;
    }
  }
}
