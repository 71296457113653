.blanket {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.menu {
  position: absolute;
  z-index: 2;
}
