@use 'sass:map';
@use 'sass:math';

@import '../assets/styles/shared';

$circle-padding: $btn-padding-y;
// stylelint-disable scss/dollar-variable-pattern
$circleSizeXs: 1.875rem;
$circleSizeSm: 3rem;
$circleSizeMd: 4rem;
$circleSizeLg: 6rem;
$circleSizeXl: 10rem;
// stylelint-enable scss/dollar-variable-pattern
$circle-sizes: (
  'xs': $circleSizeXs,
  'sm': $circleSizeSm,
  'md': $circleSizeMd,
  'lg': $circleSizeLg,
  'xl': $circleSizeXl,
);
$circle-icon-sizes: (
  'xs': 0.8,
  'sm': 1,
  'md': 1.5,
  'lg': 1.8,
  'xl': 3,
);

:export {
  circleSizeXs: strip-unit($circleSizeXs);
  circleSizeSm: strip-unit($circleSizeSm);
  circleSizeMd: strip-unit($circleSizeMd);
  circleSizeLg: strip-unit($circleSizeLg);
  circleSizeXl: strip-unit($circleSizeXl);
}

@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

@mixin circle-variant($border) {
  border-color: $border;

  & > :global(.icon) {
    color: $border;
  }
}

@mixin circle-filled-variant($background) {
  color: color-yiq($background);
  background-color: $background;
  border-color: color-yiq($background);

  & > :global(.icon) {
    color: color-yiq($background);
  }
}

.circle {
  // padding: $circle-padding;
  display: flex;

  align-items: center;

  justify-content: center;

  width: map.get($circle-sizes, 'md');
  height: map.get($circle-sizes, 'md');
  overflow: hidden;

  text-align: center;
  overflow-wrap: break-word;

  background-color: transparent;
  isolation: isolate;
  border: 2px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 25%);

  & * {
    max-width: 6rem;
    max-height: 6rem;
  }

  &.circleBorderless {
    border: none;
  }

  .circleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :global(.icon) {
    @include font-size(map.get($circle-icon-sizes, 'md') * 1em);

    margin-bottom: 0;
    stroke-width: math.div(1, map.get($circle-icon-sizes, 'md')) * 3;
  }

  :global(.btn) {
    display: flex;

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    font-size: inherit;
  }
}

@each $size, $value in $circle-sizes {
  .circle#{capitalize($size)} {
    width: $value;
    height: $value;
  }
}

@each $size, $value in $circle-icon-sizes {
  .circle#{capitalize($size)} {
    :global(.icon) {
      @include font-size($value * 1em);

      stroke-width: math.div(1, $value) * 3;
    }
  }
}

@each $color, $value in $theme-colors {
  .circle#{capitalize($color)} {
    @include circle-variant($value);

    &.circleFilled {
      @include circle-filled-variant($value);
    }
  }
}
