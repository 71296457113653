/* stylelint-disable no-descending-specificity */

@import '../../assets/styles/shared';

.logo {
  width: 5rem;

  @include media-breakpoint-up(sm) {
    width: 6rem;
  }

  @include media-breakpoint-up(md) {
    width: 7rem;
  }

  > path {
    fill: currentcolor;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.link-light) {
  > .logo > path {
    fill: $norm-blue;

    &:hover,
    &:global(.active) {
      opacity: 0.8;
    }
  }
}

/* stylelint-disable selector-class-pattern */
:global(.link-dark),
a:not(:global(.link-light)) {
  > .logo > path {
    fill: $norm-blue;

    &:hover,
    &:global(.active) {
      opacity: 0.8;
    }
  }
}
/* stylelint-enable selector-class-pattern */
