.custom-range {
  height: $custom-range-thumb-height !important;
  padding: ($custom-range-thumb-height - $custom-range-track-height) * 0.5 0 !important;
  cursor: $custom-range-track-cursor;
  appearance: initial;

  .custom-range-track {
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);

    width: $custom-range-track-width;
    height: $custom-range-track-height;

    cursor: $custom-range-track-cursor;
    user-select: none;

    background-color: $custom-range-track-bg;
    border-color: transparent;

    &:focus {
      outline: none;

      .custom-range-handle {
        box-shadow: $custom-range-thumb-focus-box-shadow;
      }
    }
  }

  .custom-range-fill {
    @include border-radius($custom-range-track-border-radius);

    height: $custom-range-track-height;
    cursor: $custom-range-track-cursor;
    user-select: none;
    background-color: theme-color('primary');
  }

  // stylelint-disable no-descending-specificity
  .custom-range-handle {
    @include gradient-bg($custom-range-thumb-bg);
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);

    position: relative;

    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    margin-top: -($custom-range-thumb-height + $custom-range-track-height) * 0.5;
    margin-left: -$custom-range-thumb-height * 0.5;

    cursor: $custom-range-track-cursor;
    user-select: none;

    border: $custom-range-thumb-border;

    appearance: none;

    &.active,
    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }

    &:focus {
      outline: none;
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
  }
}
// stylelint-enable no-descending-specificity

.custom-control-input ~ .custom-control-label {
  color: inherit !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $white !important;
}
