@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .children-#{$abbrev}y#{$infix}-#{$size} > *:not(:last-child) {
          #{$prop}-bottom: $length !important;
        }

        .children-#{$abbrev}y#{$infix}-#{$size} > *:not(:first-child) {
          #{$prop}-top: $length !important;
        }

        .children-#{$abbrev}x#{$infix}-#{$size} > *:not(:last-child) {
          #{$prop}-right: $length !important;
        }

        .children-#{$abbrev}x#{$infix}-#{$size} > *:not(:first-child) {
          #{$prop}-left: $length !important;
        }
      }
    }
  }
}
