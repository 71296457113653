@import '../assets/styles/shared';

$top-section-padding: 5rem;
$top-section-padding-lg: 10rem;

.fullScreenSection {
  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  width: 100%;
  min-width: 100vw;
  height: 100%; // IE11 Hack

  .bufferTop {
    min-height: $top-section-padding;

    @include media-breakpoint-up(lg) {
      min-height: $top-section-padding-lg;
    }
  }

  .buffer {
    flex-grow: 1;
    width: 100%;
    max-height: $top-section-padding;

    @include media-breakpoint-up(lg) {
      max-height: $top-section-padding-lg;
    }
  }

  .wrapper {
    display: flex;

    flex-grow: 1;

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 100%;

    & > :global(.container) {
      margin: 0 !important;
    }
  }
}
