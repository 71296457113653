@import '../../styles/shared';

.tableWrapper {
  height: calc(100vh - 405px);
  min-height: 500px;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;
}

.loader {
  z-index: $zindex-base;
}

.tableOverlay {
  table::before {
    position: absolute;
    z-index: $zindex-base;

    width: 100%;
    height: 100%;
    overflow-y: hidden;

    content: '';

    background-color: rgb(0 0 0 / 20%);
  }

  tbody {
    filter: blur(1px);
  }
}
