$layers: 1, 2, 3, 4, 5;

.z-index-layer {
  z-index: z-index-layer(1);
}

@each $layer in $layers {
  .z-index-layer-#{$layer} {
    z-index: z-index-layer($layer);
  }
}
