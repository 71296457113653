@font-face {
  font-family: 'Italian Plate No1';
  src: url('~@nord/ui/src/assets/fonts/ItalianPlateNo1-Regular.eot') format('eot'),
    url('~@nord/ui/src/assets/fonts/ItalianPlateNo1-Regular.ttf') format('truetype'),
    url('~@nord/ui/src/assets/fonts/ItalianPlateNo1-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Italian Plate No1 Mono';
  src: url('~@nord/ui/src/assets/fonts/ItalianPlateNo1Mono-Regular.eot') format('eot'),
    url('~@nord/ui/src/assets/fonts/ItalianPlateNo1Mono-Regular.ttf') format('truetype'),
    url('~@nord/ui/src/assets/fonts/ItalianPlateNo1Mono-Regular.woff') format('woff');
}
