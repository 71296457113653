@import '../../styles/shared';

.stickyTableHead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr {
    th {
      white-space: nowrap;
      background-color: $white;
      background-clip: padding-box;
      border-top-width: 0;
    }
  }
}
