.overviewIcon {
  position: fixed;
  right: 0;
  bottom: 0;

  svg {
    cursor: pointer;
  }
}

.loadingPage {
  min-height: 400px;
}
