@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .min-#{$abbrev}#{$infix}-#{$size} {
          min-#{$prop}: $length !important;
        }
        .max-#{$abbrev}#{$infix}-#{$size} {
          max-#{$prop}: $length !important;
        }
      }
    }

    @each $size, $length in $sizes {
      @if $length != 'auto' {
        $width-unit: strip-unit($length) * 1vw;
        $height-unit: strip-unit($length) * 1vh;

        .vw#{$infix}-#{$size} {
          width: $width-unit !important;
        }
        .min-vw#{$infix}-#{$size} {
          min-width: $width-unit !important;
        }
        .max-vw#{$infix}-#{$size} {
          max-width: $width-unit !important;
        }

        .vh#{$infix}-#{$size} {
          height: $height-unit !important;
        }
        .min-vh#{$infix}-#{$size} {
          min-height: $height-unit !important;
        }
        .max-vh#{$infix}-#{$size} {
          max-height: $height-unit !important;
        }
      }
    }
  }
}
