@use 'sass:math';

$capital-letter-ratio: math.div(10.9, 16);
$icon-container-ratio: math.div(24, 18);
$stroke-ratio: math.div(2, 24);
$icon-size-ratio: $capital-letter-ratio * $icon-container-ratio * (1 - $stroke-ratio);
$icon-inner-padding-bottom-ratio: math.div(3, 24);
$margin-bottom: $icon-size-ratio * $icon-inner-padding-bottom-ratio * 1em;
$stroke-extra-margin: $icon-size-ratio * ($stroke-ratio * 0.5) * 1em;

:export {
  sizeRatio: $icon-size-ratio;
}

.spin {
  animation: spin 2.5s linear infinite;
}

.icon {
  margin-bottom: -$margin-bottom + ($stroke-extra-margin);

  // margin-bottom: - $stroke-extra-margin;
  vertical-align: baseline;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
