.text-reset {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .text-reset;

  font-family: $font-family-base;
  font-size: $font-size-base;
  text-transform: none;
}

.text-base {
  font-family: $font-family-base;
}

.text-uppercase {
  @include text-uppercase;
}

.text-lowercase {
  @include text-lowercase;
}

@each $breakpoint, $font-size in $font-sizes {
  .font-size-#{$breakpoint} {
    @include font-size($font-size);
  }
}

@include media-breakpoint-up(lg) {
  @each $breakpoint, $font-size in $font-sizes-desktop {
    .font-size-#{$breakpoint} {
      @include font-size($font-size);
    }
  }
}

h1,
.h1 {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .font-size-xxxl;
}

h2,
.h2 {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .font-size-xxl;
}

h3,
.h3 {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .font-size-xl;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4 .h5 {
  margin-bottom: 0.35em;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .list#{$infix}-unstyled {
      @include list-unstyled;
    }
    .list#{$infix}-inline {
      @include list-unstyled;
    }

    .list#{$infix}-inline-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: $list-inline-padding;
      }
    }
  }
}
