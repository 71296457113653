// Button variants
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  --slant-top-left: 0;
  --slant-top-right: 0;
  --slant-bottom-right: 0;
  --slant-bottom-left: 0;

  font-weight: bold;
  color: $light-sand;

  background-color: $norm-blue;
  border-color: $norm-blue;
  border-radius: 0;

  /* -webkit-clip-path: polygon(0 var(--slant-top-left),calc(12 / 19 * var(--slant-top-left)) 0,calc(100% - 12 / 19 * var(--slant-top-right)) 0,100% var(--slant-top-right),100% calc(100% - var(--slant-bottom-right)),calc(100% - 12 / 19 * var(--slant-bottom-right)) 100%,calc(12 / 19 * var(--slant-bottom-left)) 100%,0 calc(100% - var(--slant-bottom-left))); */
  clip-path: polygon(
    0 var(--slant-top-left),
    calc(12 / 19 * var(--slant-top-left)) 0,
    calc(100% - 12 / 19 * var(--slant-top-right)) 0,
    100% var(--slant-top-right),
    100% calc(100% - var(--slant-bottom-right)),
    calc(100% - 12 / 19 * var(--slant-bottom-right)) 100%,
    calc(12 / 19 * var(--slant-bottom-left)) 100%,
    0 calc(100% - var(--slant-bottom-left))
  );

  @include hover {
    color: $light-sand;
    opacity: 0.8;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $background;
    background-color: transparent;
    border-color: $border;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }

    border-color: $active-border;
    box-shadow: none;
  }

  &.btn-filled {
    @include gradient-bg($background);

    color: color-yiq($background);

    @include hover {
      // color: color-yiq($hover-background);
      @include gradient-bg($hover-background);

      color: color-yiq($background, $dark: $yiq-text-light, $light: $white);

      // border-color: $hover-border;
      // border-color: color-yiq($background);
      opacity: 0.8;
    }

    &.disabled,
    &:disabled {
      @include gradient-bg($background);

      color: color-yiq($background);
      border-color: $border;
    }
  }
}
