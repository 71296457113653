@use 'sass:map';
@use 'sass:list';

@import '../../assets/styles/shared';

$top-space-mobile: 0.25rem;
$top-space-sticky: 0.25rem;
$top-space-desktop: 1.875rem;
$expand-breakpoint: lg;

// Calculate collapse breakpoint
$breakpoints: map.keys($grid-breakpoints);
$expand-breakpoint-index: list.index($breakpoints, $expand-breakpoint);
$collapse-breakpoint: list.nth($breakpoints, $expand-breakpoint-index - 1);
$slide-transition-easing: $easeInOutQuad;
$slide-transition-duration: 500;

:export {
  expandBreakpoint: $expand-breakpoint;
}
