@import '../../assets/styles/shared';

$file-transition-timeout: 500ms;
$file-transition-easing-enter: $easeOutQuad;
$file-transition-easing-exit: $easeInQuad;

:export {
  timeout: strip-unit($file-transition-timeout);
  timeoutStagger: strip-unit($file-transition-timeout * 0.2);
  itemHeight: 3.5;
}

.file {
  display: flex;

  align-items: flex-start;

  padding: 0.5625rem;
  margin: 0.5em 0;

  color: #fff;

  background-color: theme-color('secondary');
  border-radius: 0.5rem;

  transition: background-color $file-transition-timeout $easeInOutQuart;

  &.fileStatusUploaded {
    background-color: theme-color('primary');
  }

  &.fileStatusError {
    background-color: theme-color('danger');
  }

  .fileInfo {
    display: flex;
    flex-direction: column;

    .fileInfoMain {
      width: 100%;
      overflow: hidden;

      font-size: 0.75em;
      line-height: 1.2;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fileInfoSub {
      font-size: 0.625em;
      white-space: nowrap;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
    }
  }

  .fileStatus {
    display: flex;

    flex-direction: column;

    align-items: flex-end;

    margin-right: 0.625rem;
    margin-left: auto;

    .fileStatusMain {
      font-size: 0.75em;
      line-height: 1.2;
    }

    .fileStatusSub {
      font-size: 0.625em;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
    }
  }
}

.filesList {
  position: relative;
  margin: 0;
  transition-property: height;

  &.filesListEnter {
    transition-timing-function: $file-transition-easing-enter;
  }

  &.filesListExit {
    transition-timing-function: $file-transition-easing-exit;
  }
}

.fileTransition {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(-100%);

  // Transitions enter
  &.fileTransitionEnter {
    transition: transform $file-transition-timeout $file-transition-easing-enter,
      opacity $file-transition-timeout $file-transition-easing-enter,
      top $file-transition-timeout $file-transition-easing-enter;
  }

  &.fileTransitionEnterActive,
  &.fileTransitionEnterDone,
  &.fileTransitionExit {
    opacity: 1;
    transform: translateY(0%);
  }

  &.fileTransitionEnterDone {
    transition: top $file-transition-timeout $file-transition-easing-exit;
  }

  // Transitions exit
  &.fileTransitionExit {
    transition: transform $file-transition-timeout $file-transition-easing-exit,
      opacity $file-transition-timeout $file-transition-easing-exit,
      top $file-transition-timeout $file-transition-easing-exit;
  }

  &.fileTransitionExitActive {
    opacity: 0;
    transform: translateY(-100%);
  }
}
