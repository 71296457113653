/* stylelint-disable no-duplicate-selectors */

@import '../../assets/styles/shared';

$timeout: 750ms * $animationTimeoutFactor;
$translate-y: 3rem;
$easing-out: $easeOutQuint;

:export {
  timeout: strip-unit($timeout);
  easing: easeoutquint;
  translateY: -3 * 18;
}

// Base states
.slideInBottom,
.slideInTop,
.slideOutBottom,
.slideOutTop {
  opacity: 0;
  transform: translateY($translate-y);

  &.slideEnter {
    opacity: 0;
  }

  &.slideExit {
    opacity: 1;
    transform: translateY(0);
  }
}

// Active states
.slideInBottom,
.slideOutBottom {
  &.slideEnter,
  &.slideExitActive,
  &.slideExitDone {
    transform: translateY($translate-y);
  }
}

.slideInTop,
.slideOutTop {
  &.slideEnter,
  &.slideExitActive,
  &.slideExitDone {
    transform: translateY(-$translate-y);
  }
}

.slideInBottom,
.slideInTop,
.slideOutBottom,
.slideOutTop {
  &.slideExitActive,
  &.slideExitDone {
    opacity: 0;
  }

  &.slideEnterActive,
  &.slideEnterDone {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideInBottom,
.slideInTop {
  // Transitions
  &.slideEnterActive {
    transition: transform $timeout $easeOutQuint, opacity $timeout $easeOutQuint;
  }

  &.slideExitActive {
    transition: transform $timeout $easeInQuint, opacity $timeout $easeInQuint;
  }
}

.slideOutBottom,
.slideOutTop {
  // Transitions
  &.slideEnterActive {
    transition: transform $timeout $easeInQuint, opacity $timeout $easeInQuint;
  }

  &.slideExitActive {
    transition: transform $timeout $easeOutQuint, opacity $timeout $easeOutQuint;
  }
}
