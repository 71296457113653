@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/custom-forms';
@import '../../shared';

/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/at-extend-no-missing-placeholder */

.custom-react-select {
  .custom-react-select__control {
    @extend .custom-select;

    display: flex;
    padding: 0 0.875rem;

    &:hover {
      border-color: $custom-select-border-color;
    }

    &.custom-react-select__control--is-focused {
      @extend .custom-select, :focus;

      border-color: $custom-select-focus-border-color;
    }

    &.custom-react-select__control--is-disabled {
      background-color: $input-disabled-bg;

      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

    .custom-react-select__input {
      margin-right: 10px;
    }

    .custom-react-select__placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }

  .custom-react-select__value-container {
    padding: 0;
  }

  .custom-react-select__single-value {
    margin: 0;
  }

  .custom-react-select__indicators {
    display: none;
  }

  /* stylelint-disable no-descending-specificity */
  &.is-valid {
    .custom-react-select__control {
      @extend .custom-select, .is-valid;

      &:hover {
        border-color: $form-feedback-valid-color;
      }
    }
  }

  &.is-invalid {
    .custom-react-select__control {
      @extend .custom-select, .is-invalid;

      &:hover {
        border-color: $form-feedback-invalid-color;
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}

.custom-react-select__menu {
  z-index: $zindex-dropdown !important;
  color: $text-color-dark;
  border: $input-border-width $input-border-color solid;
  box-shadow: $input-box-shadow !important;

  .custom-react-select__option {
    padding: 8px 0.875rem;
  }

  .custom-react-select__option--is-selected {
    background-color: $dusk-blue;
  }
}
