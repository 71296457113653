.btn {
  font-family: $font-family-monospace;
  border-radius: 0;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;

  &:focus {
    outline: none;
  }
}

.btn:not(:disabled, .disabled):active,
.btn:not(:disabled, .disabled).active,
.btn:not(:disabled, .disabled):active:focus {
  box-shadow: none;
}
