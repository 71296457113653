@import '../../../../assets/styles/shared';

.environmentBar {
  position: fixed;
  z-index: z-index-layer($overlay-layer-index + 1);

  width: 100%;
  height: 22px;

  text-align: center;
  text-transform: uppercase;

  pointer-events: none;
}

.positionTop {
  top: 0;
}

.positionBottom {
  bottom: 0;
}

.test {
  opacity: 0.5;
}

.development,
.test {
  color: color-yiq(theme-color('success'));
  background-color: theme-color('success');
}

.staging {
  color: color-yiq(theme-color('warning'));
  background-color: theme-color('warning');
}

.unknown {
  color: color-yiq(theme-color('info'));
  background-color: theme-color('info');
}
